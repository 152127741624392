@import "./media-queries";

.App {
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-evenly;

    .darkMode {
      position: absolute;
      top: 0px;
      left: 15px;
      padding-top: 10px;

      .span {
        @include tablet {
          font-size: 2vw;
        }
      }
    }

    // Footer
    .footer {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      &__text {
        font-size: 18px;
        padding-right: 50px;
      }

      &__logo {
        font-size: 15px;
        justify-content: space-between;
        margin-top: 5px;

        a {
          margin-left: 10px;
        }
      }
    }
  }
}
