@import "media-queries";

.meanings {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 55vh;
  border: 10px solid rgb(105, 105, 105);
  border-radius: 10px;
  padding: 10px 20px;
  overflow-x: hidden;

  @include tablet {
    height: 60vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .audio {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 30px;
  }

  .subTitle {
    font-size: 2vw;
    font-family: "Noto Sans KR", sans-serif;

    @include tablet {
      font-size: 4vw;
    }
  }

  .singleMean {
    color: #000;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 10px 0;

    .hrStyle {
      background-color: #000;
      width: 100%;
    }
  }
}
