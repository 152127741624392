@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap);
body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header{display:flex;align-items:center;justify-content:space-evenly;flex-direction:column;height:20vw;width:100%}@media screen and (max-width: 768px){.header{height:25vw}}.header .title{font-size:4vw;text-transform:uppercase;font-family:"Noto Sans KR", sans-serif}@media screen and (max-width: 280px){.header .title{font-size:15vw}}.header .inputs{width:100%;display:flex;justify-content:space-around}.header .inputs .search,.header .inputs .select{width:43%}@media screen and (max-width: 280px){.header .inputs .search,.header .inputs .select{font-size:5vw}}

.meanings{display:flex;flex-direction:column;overflow-y:scroll;scrollbar-width:thin;height:55vh;border:10px solid dimgray;border-radius:10px;padding:10px 20px;overflow-x:hidden}@media screen and (max-width: 768px){.meanings{height:60vh;overflow-x:hidden;overflow-y:scroll}}.meanings .audio{background-color:#fff;border-radius:10px;width:100%;height:30px}.meanings .subTitle{font-size:2vw;font-family:"Noto Sans KR", sans-serif}@media screen and (max-width: 768px){.meanings .subTitle{font-size:4vw}}.meanings .singleMean{color:#000;display:flex;flex-direction:column;border-radius:10px;padding:10px 20px;margin:10px 0}.meanings .singleMean .hrStyle{background-color:#000;width:100%}

.App{height:100vh}.App .container{display:flex;flex-direction:column;height:100vh;justify-content:space-evenly}.App .container .darkMode{position:absolute;top:0px;left:15px;padding-top:10px}@media screen and (max-width: 768px){.App .container .darkMode .span{font-size:2vw}}.App .container .footer{display:flex;justify-content:center;margin-top:30px}.App .container .footer__text{font-size:18px;padding-right:50px}.App .container .footer__logo{font-size:15px;justify-content:space-between;margin-top:5px}.App .container .footer__logo a{margin-left:10px}

