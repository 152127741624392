@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap");

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
