@import "media-queries";

.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 20vw;
  width: 100%;

  @include tablet {
    height: 25vw;
  }

  .title {
    font-size: 4vw;
    text-transform: uppercase;
    font-family: "Noto Sans KR", sans-serif;

    @include mobile {
      font-size: 15vw;
    }
  }

  .inputs {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .search,
    .select {
      width: 43%;

      @include mobile {
        font-size: 5vw;
      }
    }
  }
}
